.p-tag.p-tag-info.grey {
    background-color: #9a9c9f !important;
    color: #ffffff !important;
}

.p-tag {
    align-self: flex-start;
}

.link {
    cursor: pointer;
    color: #495057;
    text-decoration: underline;
}