.p-overlaypanel .p-overlaypanel-content {
    padding: 0 !important;
}

.p-overlaypanel {
    background-color: hsl(0, 0%, 100%) !important;
    box-shadow: 0px 10px 15px 4px rgba(0, 0, 0, 0.7) !important;
}

.results-pane {
    max-height: 600px;
    min-width: 350px;
    max-width: 400px;
    overflow-y: auto;
}

.no-results {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 200px;
    width: 350px;
}

.result-pane-item {
    min-height: 75px;
    padding: 15px;
}

.result-pane-item:hover {
    cursor: pointer;
    background-color: #d0e1fd !important;
}

.left-info {
    display: flex;
    flex: auto;
    flex-direction: column;
    text-align: left;
}

.right-info {
    display: flex;
    flex: auto;
    flex-direction: column;
    text-align: right;
}