.info {
    display: flex;
    flex: auto;
    flex-direction: column;
    text-align: left;
}

.details {
    margin-bottom: .5em;
}

.border-top {
    border-top: 2px solid #495057
}

.card.view-pannel {
    background: var(--surface-card);
    padding: 1em 1rem;
    border-radius: 10px;
}

.p-card-title {
    padding-bottom: 0;
}

.p-card-content {
    padding: 10px 0 !important;
}

.cancelled {
    color: #EF4444
}

.refund {
    color: #EF4444
}

a {
    color: #495057 !important;
}