.row {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-basis: 100%;
    padding: 10px 20px;
    align-items: center;
}

.status {
    text-align: center;
    min-width: 85px;
}

.info {
    display: flex;
    flex: auto;
    flex-direction: column;
    text-align: left;
}

.actions {
    display: flex;
    gap: 5px;
    align-items: right;
}

.cal {
    text-align: center !important;
    border: 0 !important;
    font-size: 20px !important;
    max-width: 150px !important;
    font-weight: bold !important;
}

.small-tag {
    padding: 1px 4px !important;
    margin-left: 5px;
}

@media (max-width: 360px) {
    .info {
        text-align: left;
        margin-left: 10px;
    }
}
